<template>
  <div class="main">
    <div class="inside" v-bind:class="data.win ? 'insidewin' : ''">
      <div class="nameTxt">{{ data.name }}</div>
      <div class="pointsTxt">
        <div v-bind:class="data.chips == 0 ? 'pointsTxtDisabled' : ''">Bet{{ data.chips > 0 ? ": " + parseFloat(parseFloat(data.chips).toFixed(15)) : "" }}</div>
        <div v-bind:class="data.points == 0 ? 'pointsTxtDisabled' : ''">Points{{ data.points > 0 ? ": " + data.points : "" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BetArea",
  components: {},
  data() {
    const state = {
      totalBet: 0,
    };

    return {
      state,
    };
  },
  props: ["data"],
  mounted() {},
  watch: {
    windowSize: function () {},
  },
  methods: {
    setBet(bet) {
      window.Logger.log(bet);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #135067 C4C4C4-->
<style scoped>
.nameTxt {
  line-height: 20px;
  font-size: 16px;
}
.pointsTxt {
  margin-top: 3px;
  line-height: 18px;
  font-size: 12px;
}

.pointsTxtDisabled {
  color: #C4C4C4;
}


.main {
  float: left;
  width: 33%;
  height: 78px;
  font-family: "Noto Sans";
  line-height: 35px;
}

.insidewin {
  background-image: url(/images/winbg.svg) !important;
  color: #FFFFFF !important;
}

.inside {
  width: 103px;
  height: 78px;
  margin: 0 auto;
  background: #fbfbfb;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  font-size: 12px;
  color: #3e3e3e;
  padding-left: 12px;
  padding-top: 12px;
  text-align: left;
}
</style>
