
import Game from "../../services/game"
import Web3 from "web3";

export default class BaccaratGame extends Game {
    allCards = [
        'AC', '2C', '3C', '4C', '5C', '6C', '7C', '8C', '9C', 'TC', 'JC', 'QC', 'KC',
        'AS', '2S', '3S', '4S', '5S', '6S', '7S', '8S', '9S', 'TS', 'JS', 'QS', 'KS',
        'AD', '2D', '3D', '4D', '5D', '6D', '7D', '8D', '9D', 'TD', 'JD', 'QD', 'KD',
        'AH', '2H', '3H', '4H', '5H', '6H', '7H', '8H', '9H', 'TH', 'JH', 'QH', 'KH'
    ]
    coefs = []
    balance = 0
    win = 0
    result = []
    amount = 0.001
    currentCoefIndex = 1

    constructor(_messageHandler, _Contract, _game) {
        super(_messageHandler, _Contract, _game)
        this.logsHandler = this.getLogsHandler
    }

    setCoeffs(index) {
        this.currentCoefIndex = index
        this.coefs = this.coeffsTable[this.currentCoefIndex]
    }

    betHandler(bet_player, bet_tie, bet_dealer) {
        if (this.tokenObj.address == '0x0000000000000000000000000000000000000000') {
            this.betMainHandler(bet_player, bet_tie, bet_dealer)
        } else {
            this.betTokenHandler(bet_player, bet_tie, bet_dealer)
        }
    }

    async betTokenHandler(bet_player, bet_tie, bet_dealer) {
        this.result = []

        if (!this.connected) {
            this.messageHandler({ type: 'error', msg: 'Not connected' })
            return
        }
        this.bet_player = bet_player;
        this.bet_tie = bet_tie;
        this.bet_dealer = bet_dealer;
        this.totalBet = bet_player + bet_tie + bet_dealer;
        console.log(this.totalBet)
        this.messageHandler({ type: 'betloader' })


        var sendData = {
            from: this.account
        }

        if (this.tokenObj.extraFeeGasLimit > 0) {
            //banker_amount, uint tie_amount, uint player_amount, address token_address, uint amount
            var gasLimit = await this.contract.methods.bet(
                Web3.utils.toWei("" + this.bet_dealer, this.tokenObj.decimals),
                Web3.utils.toWei("" + this.bet_tie, this.tokenObj.decimals),
                Web3.utils.toWei("" + this.bet_player, this.tokenObj.decimals),
                this.tokenObj.address,
                Web3.utils.toWei("" + this.totalBet, this.tokenObj.decimals)
            ).estimateGas(
                {
                    from: this.account
                }
            )
            gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

            var gasPrice = await window.web3.eth.getGasPrice()
            gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


            sendData.gasLimit = gasLimit
            sendData.gasPrice = gasPrice
        }


        console.log(sendData)

        this.contract.methods
            .bet(
                Web3.utils.toWei("" + this.bet_dealer, this.tokenObj.decimals),
                Web3.utils.toWei("" + this.bet_tie, this.tokenObj.decimals),
                Web3.utils.toWei("" + this.bet_player, this.tokenObj.decimals),
                this.tokenObj.address,
                Web3.utils.toWei("" + this.totalBet, this.tokenObj.decimals)
            )
            .send(sendData)
            .then(function (result) {
                var win = Web3.utils.fromWei(result.events.Bet.returnValues.win, this.tokenObj.decimals);
                var bet = Web3.utils.fromWei(result.events.Bet.returnValues.bet, this.tokenObj.decimals);
                var banker_cards = [];
                for (let index = 0; index < result.events.Bet.returnValues.banker_cards.length; index++) {
                    const element = result.events.Bet.returnValues.banker_cards[index];
                    if (element > 0) {
                        banker_cards.push("cards/" + this.allCards[element - 1] + ".svg")
                    } else {
                        banker_cards.push("")
                    }

                }
                var banker_points = result.events.Bet.returnValues.banker_points;
                var banker_amount = Web3.utils.fromWei(result.events.Bet.returnValues.banker_amount, this.tokenObj.decimals);

                var player_cards = [];
                for (let index = 0; index < result.events.Bet.returnValues.player_cards.length; index++) {
                    const element = result.events.Bet.returnValues.player_cards[index];
                    if (element > 0) {
                        player_cards.push("cards/" + this.allCards[element - 1] + ".svg")
                    } else {
                        player_cards.push("")
                    }

                }
                var player_points = result.events.Bet.returnValues.player_points;
                var player_amount = Web3.utils.fromWei(result.events.Bet.returnValues.player_amount, this.tokenObj.decimals);



                var tie_amount = Web3.utils.fromWei(result.events.Bet.returnValues.tie_amount, this.tokenObj.decimals);

                this.messageHandler(
                    {
                        type: 'betresult',
                        win: parseFloat(parseFloat(win).toFixed(15)),
                        bet: parseFloat(parseFloat(bet).toFixed(15)),
                        tx_id: result.transactionHash,
                        banker_cards_num: result.events.Bet.returnValues.banker_cards,
                        banker_cards: banker_cards,
                        banker_points: banker_points,
                        banker_amount: banker_amount,
                        player_cards: player_cards,
                        currency: this.tokenObj.name,
                        player_cards_num: result.events.Bet.returnValues.player_cards,
                        player_points: parseFloat(parseFloat(player_points).toFixed(15)),
                        player_amount: parseFloat(parseFloat(player_amount).toFixed(15)),
                        tie_amount: parseFloat(parseFloat(tie_amount).toFixed(15)),

                    })
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                this.messageHandler({ type: 'betbutton' })
            }.bind(this))

    }


    async betMainHandler(bet_player, bet_tie, bet_dealer) {
        this.result = []

        if (!this.connected) {
            this.messageHandler({ type: 'error', msg: 'Not connected' })
            return
        }
        this.bet_player = bet_player;
        this.bet_tie = bet_tie;
        this.bet_dealer = bet_dealer;
        this.totalBet = bet_player + bet_tie + bet_dealer;
        this.messageHandler({ type: 'betloader' })


        var sendData = {
            from: this.account,
            value: Web3.utils.toWei("" + this.totalBet, this.tokenObj.decimals)
        }

        if (this.tokenObj.extraFeeGasLimit > 0) {
            var gasLimit = await this.contract.methods.bet(
                Web3.utils.toWei("" + this.bet_dealer, this.tokenObj.decimals),
                Web3.utils.toWei("" + this.bet_tie, this.tokenObj.decimals),
                Web3.utils.toWei("" + this.bet_player, this.tokenObj.decimals)
            ).estimateGas(
                {
                    from: this.account,
                    value: Web3.utils.toWei("" + this.totalBet, this.tokenObj.decimals)
                }
            )
            gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

            var gasPrice = await window.web3.eth.getGasPrice()
            gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


            sendData.gasLimit = gasLimit
            sendData.gasPrice = gasPrice
        }


        console.log(sendData)

        this.contract.methods
            .bet(
                Web3.utils.toWei("" + this.bet_dealer, this.tokenObj.decimals),
                Web3.utils.toWei("" + this.bet_tie, this.tokenObj.decimals),
                Web3.utils.toWei("" + this.bet_player,this.tokenObj.decimals)
            )
            .send(sendData)
            .then(function (result) {
                var win = Web3.utils.fromWei(result.events.Bet.returnValues.win, this.tokenObj.decimals);
                var bet = Web3.utils.fromWei(result.events.Bet.returnValues.bet, this.tokenObj.decimals);
                var banker_cards = [];
                for (let index = 0; index < result.events.Bet.returnValues.banker_cards.length; index++) {
                    const element = result.events.Bet.returnValues.banker_cards[index];
                    if (element > 0) {
                        banker_cards.push("cards/" + this.allCards[element - 1] + ".svg")
                    } else {
                        banker_cards.push("")
                    }

                }
                var banker_points = result.events.Bet.returnValues.banker_points;
                var banker_amount = Web3.utils.fromWei(result.events.Bet.returnValues.banker_amount, this.tokenObj.decimals);

                var player_cards = [];
                for (let index = 0; index < result.events.Bet.returnValues.player_cards.length; index++) {
                    const element = result.events.Bet.returnValues.player_cards[index];
                    if (element > 0) {
                        player_cards.push("cards/" + this.allCards[element - 1] + ".svg")
                    } else {
                        player_cards.push("")
                    }

                }
                var player_points = result.events.Bet.returnValues.player_points;
                var player_amount = Web3.utils.fromWei(result.events.Bet.returnValues.player_amount, this.tokenObj.decimals);



                var tie_amount = Web3.utils.fromWei(result.events.Bet.returnValues.tie_amount, this.tokenObj.decimals);

                this.messageHandler(
                    {
                        type: 'betresult',
                        win: parseFloat(parseFloat(win).toFixed(15)),
                        bet: parseFloat(parseFloat(bet).toFixed(15)),
                        tx_id: result.transactionHash,
                        banker_cards_num: result.events.Bet.returnValues.banker_cards,
                        banker_cards: banker_cards,
                        banker_points: banker_points,
                        banker_amount: banker_amount,
                        player_cards: player_cards,
                        currency: this.tokenObj.name,
                        player_cards_num: result.events.Bet.returnValues.player_cards,
                        player_points: parseFloat(parseFloat(player_points).toFixed(15)),
                        player_amount: parseFloat(parseFloat(player_amount).toFixed(15)),
                        tie_amount: parseFloat(parseFloat(tie_amount).toFixed(15)),

                    })
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                this.messageHandler({ type: 'betbutton' })
            }.bind(this))

    }

    intCardsToStr(arr) {
        var ret = [];
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index];

            window.Logger.log(element);
        }

        return ret;
    }


    /*
    getLogsHandler(event) {
        if (event.event == "Bet") {
            var betObj = {
                address: event.returnValues.player,
                tx_id: event.transactionHash,
                bet: Web3.utils.fromWei(event.returnValues.bet, 'ether'),
                win: Web3.utils.fromWei(event.returnValues.win, 'ether')
            }
            this.addBetHistory(betObj, 'ALL')
        }

    }*/


}

