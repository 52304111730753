<template>
  <div class="container pt-3">
    <div v-if="haveCashback" class="claim">
      <div class="float-left">
        <span class="responsiveElipsis">{{ cashbackAmount }} {{ currency }}</span>
      </div>
      <div class="float-right">
        <button @click="this.claimCashback()" v-bind:class="cashbackClaiming == true ? 'txtdisabled' : ''"
          title="Claim Cashback">
          CLAIM
        </button>
        <button title="About Cashback" data-toggle="modal" data-target="#exampleModalLive"
          style="margin-left: 5px; padding: 5px 15px" @click="showPopup">
          <img src="/images/info.svg" style="width: 20px; height: 20px" />
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
    <div v-if="haveCashback" class="text-start text-hint ps-1 pt-1 mb-3">
      Instant Cashback
    </div>
    <div class="row">
      <div class="col m-0 p-0">
        <div class="baccaratBoardHolder" id="baccaratBoardHolder">
          <div class="baccaratcardsarea">
            <div v-bind:class="!state.firstBet ? 'placeBets' : ''"></div>
            <img v-if="state.player.cards[0].length > 0" v-bind:src="'/' + state.player.cards[0]"
              v-bind:class="state.player.cardsAnim[0] ? 'playcardplayer_0' : ''" class="playcard" />
            <img v-if="state.player.cards[1].length > 0" v-bind:src="'/' + state.player.cards[1]"
              v-bind:class="state.player.cardsAnim[1] ? 'playcardplayer_1' : ''" class="playcard" />
            <img v-if="state.player.cards[2].length > 0" v-bind:src="'/' + state.player.cards[2]"
              v-bind:class="state.player.cardsAnim[2] ? 'playcardplayer_2' : ''" class="playcard" />

            <img v-if="state.dealer.cards[0].length > 0" v-bind:src="'/' + state.dealer.cards[0]"
              v-bind:class="state.dealer.cardsAnim[0] ? 'playcarddealer_0' : ''" class="playcard" />
            <img v-if="state.dealer.cards[1].length > 0" v-bind:src="'/' + state.dealer.cards[1]"
              v-bind:class="state.dealer.cardsAnim[1] ? 'playcarddealer_1' : ''" class="playcard" />
            <img v-if="state.dealer.cards[2].length > 0" v-bind:src="'/' + state.dealer.cards[2]"
              v-bind:class="state.dealer.cardsAnim[2] ? 'playcarddealer_2' : ''" class="playcard" />
          </div>
          <BetArea :data="state.player" @click="addBet('player')" />
          <BetArea :data="state.tie" @click="addBet('tie')" />
          <BetArea :data="state.dealer" @click="addBet('dealer')" />
        </div>
        <div class="clearfix"></div>

        <div class="buttons mb-3">
          <div class="baccaratconnect" v-if="state.buttonstate == 'unlock'">
            <button class="betbutton" @click="baccaratGame.unlock()">UNLOCK</button>
          </div>
          <div class="clearfix"></div>
          <div class="baccaratconnect" v-if="state.buttonstate == 'connect'">
            <button class="betbutton" @click="baccaratGame.connect()">
              CONNECT
            </button>
          </div>
          <div class="clearfix"></div>

          <div class="baccaratbet" v-if="state.buttonstate == 'play'">
            <div class="baccaratchips">
              <div @click="selectChip(0)" class="baccaratchipholder" v-bind:class="
                state.baccaratcurrentBet == state.betSteps[0]
                  ? 'baccaratchipholderactive'
                  : ''
              ">
                {{ state.betSteps[0] }}
              </div>
              <div @click="selectChip(1)" class="baccaratchipholder" v-bind:class="
                state.baccaratcurrentBet == state.betSteps[1]
                  ? 'baccaratchipholderactive'
                  : ''
              ">
                {{ state.betSteps[1] }}
              </div>
              <div @click="selectChip(2)" class="baccaratchipholder" v-bind:class="
                state.baccaratcurrentBet == state.betSteps[2]
                  ? 'baccaratchipholderactive'
                  : ''
              ">
                {{ state.betSteps[2] }}
              </div>
              <div @click="selectChip(3)" class="baccaratchipholder" v-bind:class="
                state.baccaratcurrentBet == state.betSteps[3]
                  ? 'baccaratchipholderactive'
                  : ''
              ">
                {{ state.betSteps[3] }}
              </div>
              <div class="baccaratchipsline clearleft"></div>
            </div>
            <div></div>
            <div class="text-start text-hint ps-1 pt-1" v-if="betAmount > 0 && haveCashback">
              Cashback amount {{ calculateCashback(betAmount) }}
              {{ currency }}
            </div>
            <div>
              <button @click="clearBets()" class="betModifiers">
                <i class="tio-clear"></i>
              </button>
              <button class="betbutton" @click="makeBet" :disabled="!state.baccaratbet">
                <span v-if="state.baccaratbetloader" class="spinner-border text-warning me-1" role="status"
                  aria-hidden="true"></span>
                BET
              </button>
            </div>
            <div class="clearleft"></div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="baccaratbet pt-1">
          <div class="betstabholder">
            <div class="betstab" v-bind:class="
              historyManager.currentBetHistoryList == 'MY'
                ? 'betstabactive'
                : ''
            " @click="historyManager.setBetHistoryList('MY')">
              MY
            </div>
            <div class="betstab" v-bind:class="
              historyManager.currentBetHistoryList == 'ALL'
                ? 'betstabactive'
                : ''
            " @click="historyManager.setBetHistoryList('ALL')">
              ALL
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="betstabbottomline"></div>

        <TokenHistoryTable :data="historyManager.betHistory" />
      </div>
    </div>

    <div class="clearfix"></div>
    <div class="betstabbottomline"></div>
    <Popup v-show="wrongNetwork.state" @close="closePopup" :title="wrongNetwork.title" :body="wrongNetwork.body"
      fontSize="normal" />
    <Popup v-show="isPopupVisible" @close="closePopup" title="INSTANT CASHBACK RULES" :body="getCashbackText()" />
    <PromoPopup
      :state="dailyCashbackPopup.state"
      @close="closePopup"
      :title="dailyCashbackPopup.title"
      :body="dailyCashbackPopup.body"
      :closeButton="true"
      fontSize="normal"
    />
  </div>
</template>

<script>
import BaccaratGame from "../index.js";
import BetArea from "./BetArea";
import gameHelper from "../../helpers/gameHelper.js";
import TokenHistoryTable from "../../components/TokenHistoryTable";

import HistoryManager from "../../../services/historymanager";
import Baccarat from "../contracts/Baccarat.min.json";

import CashbackHelper from "../../../services/cashbackhelper.js";
import Cashback from "../../../services/Cashback.min.json";

import Promo2 from "../../../services/Promo2.min.json";
import Promo2Helper from "../../../services/promo2helper";

import Popup from "@/components/Popup.vue";
import PromoPopup from "@/components/PromoPopup.vue";

import Token from "../../../services/Token.min.json";
import TokenHelper from "../../../services/tokenhelper.js";



export default {
  name: "GamePlay",
  components: {
    TokenHistoryTable,
    BetArea,
    Popup,
    PromoPopup
  },
  data() {
    const state = {
      baccaratmessage: true,
      baccaratwin: false,
      baccaratWinAmount: "0.0",
      baccaratbet: false,
      buttonstate: "connect",
      baccaratbetloader: false,
      baccaratMessageStr: "Connecting...",
      baccaratcurrentBet: 0.001,
      betSteps: [0.001, 1, 10, 100],
      dealer: {
        chips: 0,
        name: "BANKER",
        points: 0,
        win: false,
        cards: ["cards/5C.svg", "cards/6C.svg", "cards/7C.svg"],
        cardsAnim: [false, false, false],
      },
      tie: {
        chips: 0,
        name: "TIE",
        win: false,
        points: 0,
      },
      player: {
        chips: 0,
        name: "PLAYER",
        points: 0,
        win: false,
        cards: ["cards/5C.svg", "cards/6C.svg", "cards/7C.svg"],
        cardsAnim: [false, false, false],
      },
      firstBet: false,
      game: "baccarat",
    };

    var baccaratGame = null;
    var historyManager = null;
    var cashbackHelper = null;
    var promo2Helper = null
    var tokenHelper = null
    return {
      baccaratGame,
      historyManager,
      tokenHelper,
      state,
      cashbackHelper,
      promo2Helper,
      haveCashback: true,
      cashbackAmount: 0,
      cashbackClaiming: true,
      isPopupVisible: false,
      gameHelper: new gameHelper(state.game),
      baccaratBetlimits: {
        min: this.$store.getters.getConfig.games[state.game].min,
        max: this.$store.getters.getConfig.games[state.game].max,
      },
      betAmount: 0,
      currency: this.$store.getters.getCurrency,
      wrongNetwork: {
        state: false,
        title: 'Wrong Network',
        body: `You are trying to play with {{url network}} but you are connected to {{user network}}. 
                Please connect to {{url networ}} and click reload button below
                <div class="text-center"><a class="btn btn-secondary btn-sm mt-3">Reload</a></div>`,
      },
      dailyCashbackPopup: {
        state: false,
        title: "We've got you covered!",
        body: `<center><div style="text-align: left;font-size:19px;font-weight:600;">As it was your first bet today in this game, we have REFUNDED 1 TT back to your Cashback balance, to retrieve it just press the cashback "Claim" button!</div></center>`,
      }
    };
  },
  unmounted() {
    this.baccaratGame.destroy();
    this.historyManager.destroy()
    this.cashbackHelper.destroy();
    this.promo2Helper.destroy();
    this.tokenHelper.destroy()
  },

  beforeMount() {
    this.historyManager = new HistoryManager(this.historyHandler, this.state.game, Baccarat);
    this.promo2Helper = new Promo2Helper(this.messageHandler, Promo2);

    this.baccaratGame = new BaccaratGame(
      this.messageHandler,
      Baccarat,
      "baccarat"
    );
    this.baccaratGame.historyManager = this.historyManager;
    this.baccaratGame.init();
    this.tokenHelper = new TokenHelper(this.messageHandler, Token);
    this.$store.getters.getConfig.games[this.state.game].contract = this.baccaratGame.address

    this.cashbackHelper = new CashbackHelper(this.messageHandler, Cashback);
    this.haveCashback = this.$store.state.user.currency.cashback;

  },
  mounted() {
    this.$store.commit("setEnableFast", true);
    this.matchSizes();

    this.$nextTick(function () {
      window.addEventListener("resize", this.matchSizes);
    });
  },
  watch: {
    windowSize: function () {
      this.matchSizes();
    },
    "state.baccaratMessageStr": function (gameMessage) {
      if (gameMessage != "") {
        this.$toast.show(gameMessage);
      }
      this.state.baccaratMessageStr = "";
    },
    "$store.state.user.currency": function () {
      this.setCurrency(this.$store.state.user.currency);
    },
  },
  methods: {
    setCurrency(_currency) {
      this.currency = _currency.name.toUpperCase();
      this.haveCashback = _currency.cashback;

      this.baccaratGame.setCurrency(_currency);

      this.state.buttonstate = "play";
      this.minBet = this.$store.getters.getGameLimits[this.state.game].min;
      this.maxBet = this.$store.getters.getGameLimits[this.state.game].max;
    },
    historyHandler(obj, list) {
      console.log("this.baccaratGame.playeraddress " + this.baccaratGame.playeraddress)
      if (this.baccaratGame.playeraddress != obj.address) {
        this.historyManager.addBetHistory(obj, list)
      }

    },
    addBet(type) {
      var error = false;
      if (type == "player") {
        this.state.player.chips =
          this.state.player.chips + this.state.baccaratcurrentBet;
        if (this.state.player.chips > parseFloat(this.baccaratBetlimits.max)) {
          this.state.player.chips = this.baccaratBetlimits.max;
          error = true;
        }
      }

      if (type == "tie") {
        this.state.tie.chips =
          this.state.tie.chips + this.state.baccaratcurrentBet;
        if (this.state.tie.chips > parseFloat(this.baccaratBetlimits.max)) {
          this.state.tie.chips = this.baccaratBetlimits.max;
          error = true;
        }
      }

      if (type == "dealer") {
        this.state.dealer.chips =
          this.state.dealer.chips + this.state.baccaratcurrentBet;
        if (this.state.dealer.chips > parseFloat(this.baccaratBetlimits.max)) {
          this.state.dealer.chips = this.baccaratBetlimits.max;
          error = true;
        }
      }

      if (error) {
        return this.$toast.error(
          this.gameHelper.getMessage("invalid_bet_amount")
        );
      } else {
        this.betAmount = this.state.dealer.chips + this.state.tie.chips + this.state.player.chips
      }
    },
    clearBets() {
      this.state.dealer.chips = 0;
      this.state.tie.chips = 0;
      this.state.player.chips = 0;
      this.betAmount = 0
    },
    selectChip(type) {
      this.state.baccaratcurrentBet = this.state.betSteps[type];
    },
    makeBet() {
      var haveBet = false;
      if (this.state.player.chips > 0) {
        if (
          this.state.player.chips <= this.baccaratBetlimits.max &&
          this.state.player.chips >= this.baccaratBetlimits.min
        ) {
          haveBet = true;
        }
      }

      if (this.state.tie.chips > 0) {
        if (
          this.state.tie.chips <= this.baccaratBetlimits.max &&
          this.state.tie.chips >= this.baccaratBetlimits.min
        ) {
          haveBet = true;
        }
      }

      if (this.state.dealer.chips > 0) {
        if (
          this.state.dealer.chips <= this.baccaratBetlimits.max &&
          this.state.dealer.chips >= this.baccaratBetlimits.min
        ) {
          haveBet = true;
        }
      }
      if (!haveBet) {
        return this.$toast.error("You must place bet at least on one spot");
      }

      var dealer_chips = this.state.dealer.chips;
      var player_chips = this.state.player.chips;
      var tie_chips = this.state.tie.chips;

      this.state.dealer = {
        chips: dealer_chips,
        name: "BANKER",
        points: 0,
        win: false,
        cards: ["cards/5C.svg", "cards/6C.svg", "cards/7C.svg"],
        cardsAnim: [false, false, false],
      };

      this.state.tie = {
        chips: tie_chips,
        name: "TIE",
        points: 0,
        win: false,
      };
      this.state.player = {
        chips: player_chips,
        name: "PLAYER",
        points: 0,
        win: false,
        cards: ["cards/5C.svg", "cards/6C.svg", "cards/7C.svg"],
        cardsAnim: [false, false, false],
      };

      this.baccaratGame.betHandler(
        parseFloat(this.state.player.chips.toFixed(15)),
        parseFloat(this.state.tie.chips.toFixed(15)),
        parseFloat(this.state.dealer.chips.toFixed(15))
      );

      this.state.firstBet = true;
    },
    matchSizes() { },
    messageHandler(obj) {
      // if (obj.type !== "balance") {
      //   console.log(obj);
      // }
      if (obj.type == "limits") {
        window.Logger.log(obj)
        this.betAmount = 0;
        this.minBet = obj.min;
        this.maxBet = obj.max;
        this.state.betSteps[0] = parseFloat(obj.min)
        this.state.baccaratcurrentBet = parseFloat(obj.min);
        this.$store.getters.getGameLimits[this.state.game].min = this.minBet;
        this.$store.getters.getGameLimits[this.state.game].max = this.maxBet;
        this.state.baccaratbet = true;
        return;
      }
      //
      if (obj.type == "tokennotalowed") {
        this.state.buttonstate = "unlock";
        return;
      }
      if (obj.type == "tokenalowed") {
        this.state.buttonstate = "play";
        return;
      }

      if (obj.type == "cashbackadd") {
        this.cashbackAmount = obj.balance;
        if (parseFloat(this.cashbackAmount) > 0) {
          this.cashbackClaiming = false;
        }

        return;
      }

      if (obj.type == "tokenbalance") {
        console.log(obj)
        this.$store.commit("setTokenBalance", parseFloat(obj.balance).toFixed(3));
        return;
      }

      if (obj.type == "cashbackclaim") {
        this.$toast.success("Claimed " + obj.amount + " " + this.currency, {
          duration: 3000,
        });
        this.baccaratGame.fetchAccount();
        this.cashbackHelper.balanceOf();
        return;
      }
      if (obj.type == "networkchange") {
        var url = this.gameHelper.getRedirectUrl(this.state.game, obj.networkVersion)
        if (url != null) {
          window.location.href = url
        } else {
          var chains = this.gameHelper.getChainsList(this.state.game)
          window.Logger.log(chains)
          this.wrongNetwork.state = true
          this.wrongNetwork.body = "This game is not available on this Chain/Token<br/><br/>"
          this.wrongNetwork.body += "You can play this game on the next Cnain(Tokens)<br/><br/>"
          for (let index = 0; index < chains.length; index++) {
            const element = chains[index];
            this.wrongNetwork.body += `${element.blockchain_name}(${element.currency_codes.join(', ')})<br/><br/>`
          }
          this.wrongNetwork.body += "Please change to correct Chain/Token<br/>"
          this.wrongNetwork.body += `and click reload button below
                <div class="text-center"><a href="${window.location.href}" class="btn btn-secondary btn-sm mt-3">Reload</a></div>`


        }
        // 
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      if (obj.type == "accountset") {
        if (this.$store.getters.getUserWallet != obj.msg) {
          this.$store.commit("setUserWallet", obj.msg);
        }
        this.baccaratGame.playeraddress = obj.msg;
        if (this.cashbackHelper.account == null) {
          this.cashbackHelper.onConnet(obj.msg);
          this.cashbackHelper.balanceOf();
        }
        if (this.tokenHelper.account == null) {
          this.tokenHelper.onConnet(obj.msg);
          this.tokenHelper.balanceOf();
        }
        if (this.promo2Helper.account == null) {
          console.log(obj.msg)
          this.promo2Helper.onConnet(obj.msg);
        }
        if (
          this.$store.state.user.currency.address !=
          this.baccaratGame.tokenObj.address
        ) {
          this.setCurrency(this.$store.state.user.currency);
        }


        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      if (obj.type == "dailycashback") {
        //console.log(obj)
        this.dailyCashbackPopup.state = true;
      }

      //type=balance modis roca balasi ganaxlda
      if (obj.type == "balance") {
        if (obj.currency != this.currency) {
          return
        }
        let balance = parseFloat(obj.balance);
        if(obj.currency === 'TT'){
          this.$store.commit("setBalance", balance.toFixed(4));
        }else{
          this.$store.commit("setBalance", balance.toFixed(5));
        }
        
        this.$store.commit("setCurrency", obj.currency);

        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      //type=bethistory modis roca beti dafiksirda obj.address obj.bet obj.win
      if (obj.type == "bethistory") {
        window.Logger.log(obj);

        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      //type=error modis roca shecdoma dafiksirda
      if (obj.type == "error") {
        this.state.baccaratMessageStr = obj.msg;
        this.state.baccaratmessage = true;
        setTimeout(
          function () {
            this.state.baccaratmessage = false;
          }.bind(this),
          3000
        );
      } else {
        this.state.baccaratwin = false;
        this.state.baccaratbet = false;
        this.state.baccaratbetloader = false;
        this.state.buttonstate = "play";

        //type=connected modis roca metamaskze dakonektda
        if (obj.type == "connected") {
          this.$store.commit("setCurrencyEnabled", this.$store.getters.getConfig.games[this.state.game].tokens);
          this.state.baccaratmessage = false;
          this.state.baccaratbet = true;

        }

        //type=rejected modis roca userma metamaskze rejecti misca konektze da vachvenebt konektis gilaks
        //type=notconnected  modis roca useri araa dakonektebuli da vachvenebt konektis gilaks
        //da vmalavt mesijis divs
        if (obj.type == "rejected" || obj.type == "notconnected") {
          this.state.buttonstate = "connect";
          this.state.baccaratmessage = false;
        }

        //type=betloader modis roca beti gavida blockchainze da velodebit dadasturebas, vachvenenbt preloaders
        if (obj.type == "betloader") {
          this.state.baccaratbetloader = true;
        }

        //type=betfinished modis roca userma an moigo an waago obj.win ari tanxa
        // tu tanxa=0 chveulebrivad betis knopkas vachvenebt
        // tu tanxa>0 chveulebrivad betis knopkas vachvenebt
        if (obj.type == "betfinished") {
          this.tokenHelper.balanceOf();
          if (obj.win > 0) {
            this.state.baccaratwin = true;
            this.state.baccaratWinAmount = obj.win;

            //or wamshi vmalavt winis mesijs da vachvenebt betis knopkas
            this.$toast.success(
              this.gameHelper.getMessage("you_won_amount", obj.win),
              { duration: 3000 }
            );
            this.$soundManager.play({ name: "win" });


            this.state.baccaratwin = false;
            this.state.baccaratbet = true;
          } else {
            this.state.baccaratbet = true;
          }
          this.historyManager.addAllBetHistory({
            address: this.baccaratGame.playeraddress,
            bet: obj.bet,
            tx_id: obj.tx_id,
            win: obj.win,
            currency: obj.currency,
          });
          if (this.baccaratGame.erc20Game) {
            this.baccaratGame.erc20Game.balanceOf()
          } else {
            this.baccaratGame.fetchAccount();
          }
        }

        if (obj.type == "betresult") {
          this.state.dealer.cards = obj.banker_cards;
          this.state.dealer.cardsAnim = [false, false, false];

          this.state.player.cards = obj.player_cards;
          this.state.player.cardsAnim = [false, false, false];
          var timeouts = [100, 400, 700, 1000, 1500, 2000, 3000];
          if (this.$store.getters.getFastOn) {
            timeouts = [10, 40, 70, 100, 150, 200, 300];
          }

          setTimeout(
            function () {
              this.state.player.cardsAnim[0] = true;
              this.$soundManager.play({ name: "card" });
            }.bind(this),
            timeouts[0]
          );
          setTimeout(
            function () {
              this.state.player.cardsAnim[1] = true;
              this.$soundManager.play({ name: "card" });
            }.bind(this),
            timeouts[1]
          );

          setTimeout(
            function () {
              this.state.dealer.cardsAnim[0] = true;
              this.$soundManager.play({ name: "card" });
            }.bind(this),
            timeouts[2]
          );
          setTimeout(
            function () {
              this.state.dealer.cardsAnim[1] = true;
              this.$soundManager.play({ name: "card" });
            }.bind(this),
            timeouts[3]
          );

          if (this.state.player.cards[2].length > 0) {
            setTimeout(
              function () {
                this.state.player.cardsAnim[2] = true;
                this.$soundManager.play({ name: "card" });
              }.bind(this),
              timeouts[4]
            );
          }

          if (this.state.dealer.cards[2].length > 0) {
            setTimeout(
              function () {
                this.state.dealer.cardsAnim[2] = true;
                this.$soundManager.play({ name: "card" });
              }.bind(this),
              timeouts[5]
            );
          }
          this.state.baccaratbet = true;

          setTimeout(
            function () {
              this.state.dealer.points = obj.banker_points;
              this.state.dealer.win = false;
              this.state.player.points = obj.player_points;
              this.state.player.win = false;
              this.state.tie.win = false;
              if (obj.banker_points > obj.player_points) {
                this.state.dealer.win = true;
              }
              if (obj.banker_points == obj.player_points) {
                this.state.tie.win = true;
              }
              if (obj.banker_points < obj.player_points) {
                this.state.player.win = true;
              }
              obj.type = "betfinished";
              this.messageHandler(obj);
            }.bind(this),
            timeouts[6]
          );
        }

        if (obj.type == "betbutton") {
          this.state.baccaratbet = true;
        }
      }
    },
    claimCashback() {
      this.cashbackClaiming = true;
      this.cashbackHelper.claim();
    },
    calculateCashback(betAmount) {
      if (betAmount == 0) {
        return ""
      }
      if (betAmount < 3000) {
        return (betAmount * 50) / 10000;
      } else if (betAmount < 5000) {
        return (betAmount * 60) / 10000;
      } else if (betAmount < 10000) {
        return (betAmount * 70) / 10000;
      } else {
        return (betAmount * 80) / 10000;
      }
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
      this.wrongNetwork.state = false
    },
    getCashbackText() {
      return `<table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>= 10 000</td>
                    <td>0.8%</td>
                  </tr>
                  <tr>
                    <td>< 10 000</td>
                    <td>0.7%</td>
                  </tr>
                  <tr>
                    <td>< 5 000</td>
                    <td>0.6%</td>
                  </tr>
                  <tr>
                    <td>< 3 000</td>
                    <td>0.5%</td>
                  </tr>
                </tbody>
              <table>

              Example table:<br>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10 000</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <td>5 000</td>
                    <td>35</td>
                  </tr>
                  <tr>
                    <td>3 000</td>
                    <td>18</td>
                  </tr>
                  <tr>
                    <td>1 000</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>200</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </table>

              * Cashback is rewarded only on lost bets<br>
              * Cashback is rewarded for any bet size<br>
              * Cashback has no wagering terms, can be claimed instantly<br>`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #135067-->
<style scoped>
.playcardplayer_0 {
  left: 10px !important;
  top: calc(100vmin / 1.8 - 180px) !important;
  transition: 0.5s;
  opacity: 1 !important;
}

.playcardplayer_1 {
  left: 50px !important;
  top: calc(100vmin / 1.8 - 160px) !important;
  transition: 0.5s;
  opacity: 1 !important;
}

.playcardplayer_2 {
  left: 90px !important;
  top: calc(100vmin / 1.8 - 140px) !important;
  transition: 0.5s;
  opacity: 1 !important;
}

.playcarddealer_0 {
  left: calc(100% - 200px) !important;
  top: 10px !important;
  transition: 0.5s;
  opacity: 1 !important;
}

.playcarddealer_1 {
  left: calc(100% - 160px) !important;
  top: 30px !important;
  transition: 0.5s;
  opacity: 1 !important;
}

.playcarddealer_2 {
  left: calc(100% - 120px) !important;
  top: 50px !important;
  transition: 0.5s;
  opacity: 1 !important;
}

.playcard {
  width: 110px;
  position: absolute;
  left: calc(100% + 100px);
  top: -100px;
  transition: 0.5s;
  opacity: 0;
}

.betModifiers {
  width: 18%;
  background: #fff;
  border-radius: 8px;
  font-size: 25px;
  color: #333333;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
  margin-right: 10px;
  border: none;
}

.baccaratcardsarea {
  height: calc(100vmin / 1.8);
  position: relative;
  overflow: hidden;
}

.baccaratbetarea {
  height: 60px;
  width: 90%;
  margin: 0 auto;
}

.baccaratchips {
  width: 360px;
  height: 54px;
  margin: 0 auto;
}

.baccaratchipsline {
  width: 335px;
  height: 2px;
  background: #d1d1d1;
  margin: 0 auto;
}

.baccaratchipholder {
  width: 80px;
  height: 46px;
  float: left;
  background-image: url(/images/chip.svg);
  background-repeat: no-repeat;
  color: #292929;
  line-height: 72px;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
}

.baccaratchipholderactive {
  background-image: url(/images/chipselected.svg);
}

.baccaratBoardHolder {
  height: calc(100vmin / 1.8);
}

.clearleft {
  clear: left;
}

.betstabbottomline {
  height: 2px;
  background: #d6d6d6;
  width: 90%;
  margin: 0 auto;
}

.betstabholder {
  width: 195px;
  height: 25px;
  margin: 0px auto;
}

.betstab {
  width: 95px;
  float: left;
  margin-left: 2px;
  color: var(--tabtxt-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(0deg, var(--tabgs-color) 0%, var(--tabge-color) 100%);
  transition: 0.3s;
}

.betstabactive {
  color: var(--tabtxtselected-color);
  background: linear-gradient(0deg, var(--tabys-color) 0%, var(--tabye-color) 100%);
  transition: 0.3s;
}

.betstabHolder {
  justify-content: center;
  align-items: center;
}

.buttons {
  margin: 60px 0px;
}

.baccaratconnect,
.baccaratbet {
  max-width: 362px;
  margin: 0 auto;
}

.baccaratmessage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #cbcbcb;
}

.baccaratmessage {
  z-index: 1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.betbutton {
  width: 64%;
  background: #3a3a3c;
  border-radius: 8px;
  font-size: 25px;
  color: #ffffff;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
  border: none;
}

.placeBets {
  background-image: url(/images/pybs.svg);
  display: block;
  min-width: 100%;
  min-height: 100%;
  margin-left: 10px;
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (max-width: 992px) {
  .buttons {
    margin: 30px 0px;
  }
}


@media (max-width: 401px) {
  .responsiveElipsis {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.claim {
  width: 100%;
  background: #fff;
  padding: 10px 12px;
}

.claim span {
  margin-top: 4px;
  display: inline-block;
}

.claim button {
  border: none;
  outline: none;
  padding: 5px 30px;
  border-radius: 5px;
  color: #333333;
}

.claim img {
  position: relative;
  top: -1px;
}
</style>
